import './styles/app.scss';
import './styles/cookieconsent.scss';

import 'jquery'
// loads the Bootstrap plugins
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/carousel';

// loads the code syntax highlighting library
import './js/highlight.js';

// Creates links to the Symfony documentation
import './js/doclinks.js';

// start the Stimulus application
import 'bootstrap';

const $ = require('jquery');
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require('bootstrap');

import './js/flatpicker';

import '@popperjs/core';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

import lax from 'lax.js';

import flatpickr from "flatpickr";

import Cookies from 'js.cookie';

flatpickr('#form_time', {
    enableTime: true,
    minDate: new Date().fp_incr(7),
    minTime: "08:00",
    maxTime: "17:00",
    dateFormat: "d.m.Y v H:i",
});

// ..
AOS.init();

const myCarouselElement = document.querySelector('#carouselExampleCaptions');

$('#ageCheck').modal({
    keyboard: false,
    backdrop: 'static'
});

$('#ageCheckFalse').modal({
    keyboard: false,
    backdrop: 'static'
});

$(document).ready(function () {

    //Cookies.remove('ageCheck');

    if((Cookies.get('ageCheck') == 'false')){
        $('#ageCheckFalse').modal('show');
    };

    if((Cookies.get('ageCheck') == undefined)) {
        $('#ageCheck').modal('show');
        $('#ageCheck .ageButton button').on('click', function(){
            // Extract info from data-bs-* attributes
            const check = $(this).data('bs-check')
            // If necessary, you could initiate an Ajax request here
            // and then do the updating in a callback.
            if(check == 1) {
                Cookies.set('ageCheck', 'true', { expires: 1 });
            } else {
                Cookies.set('ageCheck', 'false', { expires: 1 });
                $('#ageCheckFalse').modal('show');
            }
        });
    }

    var scroll = $(this).scrollTop();
    if (scroll > 0) {
        $('header').css({"background-color":"#fff"}, 300);
        $('.nav-container .white-lines .line').css({"background":"#000"}, 300);
        $('header .nav-link').css({"color":"#000"}, 300);
    } else {
        $('header').css({"background-color":"transparent"}, 300);
        $('.nav-container .white-lines .line').css({"background":"#fff"}, 300);
        $('header .nav-link').css({"color":"#fff"}, 300);
    }

    $(document).scroll(function () {
        var scroll = $(this).scrollTop();
        if (scroll > 0) {
            $('header').css({"background-color":"#fff"}, 300);
            $('.nav-container .white-lines .line').css({"background":"#000"}, 300);
            $('header .nav-link').css({"color":"#000"}, 300);
        } else {
            $('header').css({"background-color":"transparent"}, 300);
            $('.nav-container .white-lines .line').css({"background":"#fff"}, 300);
            $('header .nav-link').css({"color":"#fff"}, 300);
        }
    });
});

window.onload = function () {
    window.lax = { presets: lax.presets }
    lax.init()

    lax.addDriver('scrollY', function() {
        return window.scrollY
    })

    // Add animation bindings to elements
    lax.addElements('.floating-img', {
        scrollY: {
            translateY: [
                [0, 150, 700],
                ["-pageHeight" - 64, 100, 700],
            ],
            scale: [
                [0, 300, 550, 650],
                [1, 1, 1 ,0.4]
              ]
        }
    })
    // Add animation bindings to elements
    lax.addElements('.floating-img-sm', {
        scrollY: {
            translateY: [
                [0, 150, 645],
                ["-pageHeight" - 64, 100, 645],
            ],
            scale: [
                [0, 300, 550, 650],
                [1, 1, 1 ,0.35]
              ]
        }
    })

    lax.addElements('.floatingLeftBox', {
        scrollY: {
            translateY: [
                [0, 50, 195],
                [0, 50, 195],
            ]
        }
    })

    // Add animation bindings to elements
    lax.addElements('.floating-img-small', {
        scrollY: {
            translateY: [
                [0, 150, 760],
                ["-pageHeight" - 64, 100, 825],
            ],
            scale: [
                [0, 300, 550, 760],
                [1, 1, 1 ,0.4]
              ]
        }
    })

    // Add animation bindings to elements
    lax.addElements('.floating-img-lezak', {
        scrollY: {
            translateY: [
                [0, 150, 690],
                ["-pageHeight" - 64, 100, 690],
            ],
            scale: [
                [0, 300, 550, 650],
                [0.6, 0.6, 0.6 ,0.4]
              ]
        }
    })

    // Add animation bindings to elements
    lax.addElements('.floating-chmel', {
        scrollY: {
            translateY: [
                ["elInY", "elCenterY", "elOutY"],
                [0, 0, 350],
            ]
        }
    })
}

$(document).ready(function() {
    $('.minus').click(function () {
      var $input = $(this).parent().find('input');
      console.log($input.val());
      var count = parseInt($input.val()) - 1;
      count = count < 0 ? 0 : count;
      $input.val(count);
      $input.change();
      return false;
    });
    $('.plus').click(function () {
      var $input = $(this).parent().find('input');
      console.log($input.val());
      $input.val(parseInt($input.val()) + 1);
      $input.change();
      return false;
    });

    var dataForForm = document.getElementById("exampleModalToggle");

    $('input#form_adult').change(function() {
        // form data 
        const modalBodyInput = dataForForm.querySelector('input#form_price');
        // text data
        const priceShow = dataForForm.querySelector('.finalprice')
        var helper = ($("input#form_adult").val()) * 160;
        //if($(".bigbagopt").val() != 0)
        //  var helperSec = 200 * $("input[name=counter]").val();
        //else
        var helperSec = ($("input#form_kids").val()) * 110;
    
        priceShow.textContent = `${helper + helperSec} Kč`;
        modalBodyInput.value = helper + helperSec
    });

    $('input#form_kids').change(function() {
        // form data 
        const modalBodyInput = dataForForm.querySelector('input#form_price');
        // text data
        const priceShow = dataForForm.querySelector('.finalprice')
        var helper = ($("input#form_kids").val()) * 110;
        //if($(".bigbagopt").val() != 0)
        //  var helperSec = 200 * $("input[name=counter]").val();
        //else
        var helperSec = ($("input#form_adult").val()) * 160;
    
        priceShow.textContent = `${helper + helperSec} Kč`;
        modalBodyInput.value = helper + helperSec
    });
});

var slider = tns({
    "container": '.my-slider',
    "controlsContainer": "#customize-controls",
    "autoWidth": true,
    "edgePadding": 50,
    "gutter": 10,
    "center": true,
    "mouseDrag": true,
    "swipeAngle": false,
    "controls": true,
    "nav": false,
    "speed": 400
});




